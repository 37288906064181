<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
      <!-- BOC:[error] -->
      <AError
        v-if="api.isError"
        :api="api"
        :callbackReset="() => (api.isError = false)"
      ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Create New Institution</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <v-form ref="formAdd" v-model="valid" lazy-validation>
          <div class="form-content text-center">
            <div class="text-center my-0 py-0">
              <TextField
                :value="institutionName"
                :rules="institutionNameRules"
                label="Institution name"
                type="text"
                @input="institutionName = $event"
              ></TextField>
            </div>
            <div class="text-center my-0 py-0">
              <TextField
                :value="principalName"
                label="Principal name"
                :rules="principalNameRules"
                type="text"
                @input="principalName = $event"
              ></TextField>
            </div>
            <div class="text-center my-0 py-0">
              <TextField
                :value="institutionTown"
                label="Institution town"
                :rules="institutionTownRules"
                type="text"
                @input="institutionTown = $event"
              ></TextField>
            </div>
            <div class="text-center my-0 py-0">
              <TextField
                :value="applicantName"
                label="Applicant name"
                :rules="applicantNameRules"
                type="text"
                @input="applicantName = $event"
              ></TextField>
            </div>
            <div class="text-center my-0 py-0">
              <SelectField
                :value="applicantPosition"
                :field="selectField"
                :rules="applicantPositionRules"
                @input="applicantPosition = $event"
                label="Applicant position"
              ></SelectField>
            </div>
            <div class="text-center my-0 py-0">
              <Mobile
                :value="applicantMobile"
                :code="code"
                label="Applicant mobile number"
                :rules="applicantMobileRules"
                @input="applicantMobile = $event"
                @getCode="code = $event"
              ></Mobile>
            </div>
            <div class="text-center my-0 py-0">
              <TextField
                :value="applicantEmail"
                label="Applicant email address"
                type="text"
                :rules="applicantEmailRules"
                @input="applicantEmail = $event"
              ></TextField>
            </div>
          </div>
        </v-form>
        <!-- <v-card class="d-flex align-center justify-center" width="250" height="250">
          <div class="text-center">
            <v-btn rounded color="primary" dark> register </v-btn>
          </div>
      </v-card> -->
      </div>
      <div class="d-flex flex-wrap justify-space-around py-3">
        <v-btn
          class="mt-3"
          width="150px"
          rounded
          outlined
          color="primary"
          dark
          :to="{ name: 'PageEducatorDashboard' }"
        >
          Cancel
        </v-btn>

        <v-btn
          width="150px"
          class="mt-3"
          rounded
          color="primary"
          dark
          :loading="api.isLoading"
          @click="submit()"
        >
          submit
        </v-btn>
      </div>
      <v-divider ></v-divider>
      <div class="pt-3 text-right" >Contact us for support.</div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import TextField from "@/components/Form/TextField.vue";
import SelectField from "@/components/Form/SelectField.vue";
import datasetPosition from "@/datasets/institutionPosition";
import Mobile from '@/components/Form/Mobile.vue';
export default {
  components: {
    TextField,
    SelectField,
    Mobile,
  },
  computed: mapState({
    auth: (state) => state.auth.data
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    mobile:null,
    code:null,
    institutionName: null,
    institutionTown: null,
    applicantName: null,
    applicantMobile: null,
    applicantEmail: null,
    applicantPosition: null,
    principalName: null,
    institutionNameRules: [],
    applicantPositionRules: [],
    institutionTownRules: [],
    applicantNameRules: [],
    applicantMobileRules: [],
    applicantEmailRules: [],
    principalNameRules: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectField: {
      defaultValue: "",
      name: "Applicant Position",
      options: datasetPosition,
      optionText: "name",
      optionValue: "value",
    },
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageEducatorDashboard" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "New Institution",
      to: { name: "PageEducatorInstitutionAdd" },
      exact: true,
    });

    //EOC

    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/application/add";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({ name: "PageEducatorDashboard" });
    };
  },
  mounted() {
    this.applicantName = this.auth.User.name
    this.code = this.auth.Account.countryCode
    this.applicantMobile =  this.auth.Account.mobile
    if(this.auth.Account.email){
      this.applicantEmail = this.auth.Account.email
    }
  },
  watch:{
    institutionName: function () {
      this.institutionNameRules = [];
    },
    institutionTown: function () {
      this.institutionTownRules = [];
    },
    applicantName: function () {
      this.applicantNameRules = [];
    },
    applicantMobile: function () {
      this.applicantMobileRules = [];
    },
    applicantEmail: function () {
      this.applicantEmailRules = [];
    },
    principalName: function () {
      this.principalNameRules = [];
    },
    applicantPosition: function () {
      this.applicantPositionRules = [];
    },
  },
  methods: {
    selectType(item) {
      this.selectedType = item.id;
    },
    submit() {
      this.institutionNameRules = [(v) => !!v || "Institution name is required"];
      this.applicantPositionRules = [(v) => !!v || "Applicant position is required"];
      this.institutionTownRules = [(v) => !!v || "Institution town is required"];
      this.applicantNameRules = [(v) => !!v || "Applicant name is required"];
      this.applicantMobileRules = [
        () =>
          (!!this.applicantMobile &&
            this.applicantMobile.length >= 7 &&
            this.applicantMobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.applicantMobile)) ||
          "The Mobile number must be an integer number",
      ];
      this.applicantEmailRules = [
        (v) => !!v || "Applicant email address is required",
      ];
      this.principalNameRules = [(v) => !!v || "Principal name is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formAdd.validate()) {
          self.api.params = {
            institutionName: self.institutionName,
            principalName: self.principalName,
            institutionTown: self.institutionTown,
            applicantName: self.applicantName,
            applicantPosition: self.applicantPosition,
            applicantMobile: self.applicantMobile,
            applicantEmail: self.applicantEmail,
          };

          self.$api.fetch(self.api);
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
    increase() {
      if (this.isClickSocial) {
        this.step = 6;
      } else {
        this.step++;
      }
    },
    clickSocial() {
      this.isClickSocial = true;
      this.step = 3;
    },
    decrease() {
      this.step--;
    },
  },
};
</script>
<style scoped>
.form-content {
  width: 300px !important;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.v-stepper__header {
  box-shadow: none !important;
}
</style>