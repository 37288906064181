<template>
  <v-select
    v-model="inputVal"
    outlined
    :label="field.name"
    :items="field.options"
    :item-text="field.optionText"
    :item-value="field.optionValue"
    :rules="rules"
  ></v-select>
</template>

<script>
export default {
  props: ["field", "value","rules"],
  components: {
    //
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
    password: null,
    show: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>