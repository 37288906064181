export default [
  {
    id: 1,
    name: "Principal",
    value: "principal",
  },
  {
    id: 2,
    name: "Deputy Principal",
    value: "deputyPrincipal",
  },
  {
    id: 3,
    name: "Teacher",
    value: "teacher",
  },
  {
    id: 4,
    name: "Admin",
    value: "admin",
  },
  {
    id: 5,
    name: "Others",
    value: "others",
  },
]